import React, { useEffect, useState } from "react";

import { Autocomplete, Box, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";

import { Dashboard } from "../../../untils/icons";
import BarChartVertical from "./BarChartVertical";
import GridTable from "./GridTable";
import GridTableHosp from "./GridTableHosp";

function K111HypertensionCard({ data, dataTbl2, header, hname, year, setYear, yearsData, dataGraph, profile, onSearch, dataHname }) {
    // console.log("dataSelect:", dataSelect);
    const [cleared, setCleared] = useState(false);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                        <Grid item xs={12} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize="medium" />
                                <Typography className="main-header">K111 โรคความดัน</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} mt={3}>
                            <FormControl fullWidth>
                                <InputLabel shrink>ปีงบประมาณ</InputLabel>
                                <Select
                                    name="year"
                                    value={year}
                                    label="เลือกปีงบประมาณ"
                                    required
                                    onChange={(e) => setYear(e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        เลือกปีงบประมาณ
                                    </MenuItem>
                                    {yearsData.map((el) => (
                                        <MenuItem key={el.value} value={el.value}>
                                            {Number(el.value) + 543}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} mt={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disabled={profile.role_id !== 1 && profile.role_id !== 2}
                                    fullWidth
                                    getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hname}`)}
                                    options={dataHname}
                                    autoComplete
                                    includeInputInList
                                    filterSelectedOptions
                                    value={hname}
                                    noOptionsText="No hname"
                                    onChange={(event, newValue) => {
                                        onSearch("hname", newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="เลือกรพ.สต." />}
                                ></Autocomplete>
                            </FormControl>
                        </Grid>

                        {/* Chart */}
                        {data && (
                            <Grid item xs={12}>
                                <Box className="census-info-frame-header">
                                    <Typography className="text-info-header">
                                        ประชากร 35 ปีขึ้นไปที่ได้รับการคัดกรอง และเสี่ยงต่อโรคความดัน
                                    </Typography>
                                </Box>
                                <Box className="census-info-frame" style={{ height: "500px" }}>
                                    <BarChartVertical information={dataGraph} />
                                </Box>
                            </Grid>
                        )}
                        {/* Table */}
                        <Grid item xs={12}>
                            <GridTableHosp data={data} header={header} />
                        </Grid>
                        <Grid item xs={12}>
                            <GridTable dataTbl2={dataTbl2} />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

export default K111HypertensionCard;
