import React, { useEffect, useState } from "react";

import { Autocomplete, Box, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";

import BarChartAge from "./BarChartAge";
import BarChartTopDiagnose from "./BarChartTopDiagnose";
import BarChartYear from "./BarChartYear";
import LeafletMapComponent from "./LeafletMapComponent";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import female from "../../../assets/images/female.png";
import male from "../../../assets/images/male.png";

function ReportCard({
    subdistrictObj,
    dataLocation,
    ageData,
    topData,
    yearData,
    sexData,
    date_start,
    date_end,
    setDateEnd,
    setDateStart,
    profile,
    searchLoca,
    setSearchLoca,
    setInputLoca,
    setHName,
    sex,
    setSex,
    sextype,
}) {
    const count = sexData.map((e) => e.count);
    const total = parseInt(count[0]) + parseInt(count[1]);
    const [cleared, setCleared] = useState(false);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={2}>
                        <Grid item xs={12}>
                            <Box className="census-info-header">
                                <Stack className="flex-center" spacing={1}>
                                    <Typography className="text-info-header">รายงานการวินิจฉัยโรคจากข้อมูลหน่วยบริการในจังหวัด</Typography>
                                    <Typography className="text-info-sub">หมายเหตุ : ข้อมูลจริงบนระบบทดสอบยังไม่สามารถใช้เพื่อการเผยแพร่</Typography>
                                </Stack>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} my={2}>
                            <Grid container columns={12} spacing={3} className="flex-start">
                                <Grid item xs={12} sm={12} md={5}>
                                    <Stack width="100%" direction="row" spacing={1}>
                                        <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                            <Typography mt={2} color="black">
                                                ตั้งแต่:
                                            </Typography>
                                        </Box>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(date_start)}>
                                                <DatePicker
                                                    // fullWidth
                                                    value={date_start}
                                                    slotProps={{
                                                        field: { clearable: true, onClear: () => setCleared(true) },
                                                    }}
                                                    label="วันที่เริ่มต้น"
                                                    format="DD/MM/YYYY"
                                                    onChange={(e) => setDateStart(e)}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                        <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                            <Typography mt={2} color="black">
                                                จนถึง:
                                            </Typography>
                                        </Box>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(date_end)}>
                                                <DatePicker
                                                    fullWidth
                                                    value={date_end}
                                                    slotProps={{
                                                        field: { clearable: true, onClear: () => setCleared(true) },
                                                    }}
                                                    label="วันที่สิ้นสุด"
                                                    format="DD/MM/YYYY"
                                                    onChange={(e) => setDateEnd(e)}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={5}>
                                    <Autocomplete
                                        disabled={profile.role_id !== 1 && profile.role_id !== 2}
                                        fullWidth
                                        getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                                        filterOptions={(x) => x}
                                        options={dataLocation}
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOptions
                                        value={searchLoca}
                                        noOptionsText="No locations"
                                        onChange={(event, newValue) => {
                                            // console.log("newValue", newValue);
                                            setSearchLoca(newValue);
                                            if (!newValue) {
                                                return;
                                            }
                                            if (newValue) {
                                                setHName(newValue.hname);
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setInputLoca(newInputValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="รพ.สต." />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="sex">เพศ</InputLabel>
                                        <Select labelId="sex" id="sex" value={sex} label="sex" onChange={(e) => setSex(e.target.value)}>
                                            {sextype &&
                                                sextype.map((el, i) => (
                                                    <MenuItem key={i + 1} value={el.value} disabled={el.disabled}>
                                                        {el.label}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">จำนวนประชากรที่วินิจฉัยโรค แยกตามเพศ</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <Stack mt={1} mb={1} width="50%" margin={2}>
                                    <Typography align="center" style={{ backgroundColor: "lightblue" }}>
                                        {count[0]}
                                    </Typography>
                                    <Box align="center">
                                        <img src={male} width="71%" height="100%" alt="men" />
                                    </Box>
                                    <Typography align="center" style={{ backgroundColor: "lightblue" }}>
                                        ชาย
                                    </Typography>
                                </Stack>
                                <Stack width="50%">
                                    <Typography align="center" style={{ backgroundColor: "pink" }}>
                                        {count[1]}
                                    </Typography>
                                    <Box align="center">
                                        <img src={female} width="71%" height="100%" alt="women" />
                                    </Box>
                                    <Typography align="center" style={{ backgroundColor: "pink" }}>
                                        หญิง
                                    </Typography>
                                </Stack>
                                <Box
                                    sx={{ border: "solid 2px #2A7EDC", borderRadius: "100%", width: "150px", height: "120px", marginRight: "5px" }}
                                    textAlign="center"
                                >
                                    <Stack mt={4}>
                                        <Typography>ทั้งหมด: {total}</Typography>
                                        <Typography>ไม่ระบุ: {count[2]}</Typography>
                                    </Stack>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">จำนวนประชากรที่วินิจฉัยโรค แยกตามกลุ่มอายุ</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <BarChartAge ageData={ageData} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">การวินิจฉัยโรคหรือการการบาดเจ็บ</Typography>
                            </Box>
                            <Box
                                className="census-info-frame-box"
                                style={{ width: "100%", height: "710px", overflowY: "hidden", overflowX: "hidden" }}
                            >
                                <BarChartTopDiagnose topData={topData} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">จำนวนประชากรที่วินิจฉัยโรคหรืออาการบาดเจ็บแยกรายปี</Typography>
                            </Box>
                            <Box className="census-info-frame" height={400}>
                                <BarChartYear yearData={yearData} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">
                                    จำนวนประชากรที่วินิจฉัยโรคหรืออาการบาดเจ็บ ในแต่ละพื้นทรายตำบล(คน)
                                </Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <LeafletMapComponent subdistrictObj={subdistrictObj} />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

export default ReportCard;
